import React from 'react'
import { StaticQuery, graphql } from "gatsby"
  
export default () => (
  <StaticQuery
    query={graphql`
        query {
            allContentfulBlogPost(limit: 1000) {  
                edges {
                    node {
                        title
                    }
                }
            } 
        }
    `}
    render={data => (
        <div>{ data.allContentfulBlogPost.edges.map(({ node }) => (
            <div key={node.id}>
              <div>{node.title}</div>
            </div>
          ))}
        </div>
    )}
  />
)